<template>
  <div class='container'>
    <h5>优惠券</h5>
    <div v-if="couponList.length" class="content">

      <div class="coupon-item" v-for="(item,index) in couponList" :key="index">
        <div class="discounts" :style="{backgroundImage: time >=item.expiry_time ?   'url('+ coupons_bg_expire+')' : 'url('+ coupons_bg+')'}">
          <span>{{ item.discount * 10 }}</span><i>折</i>
        </div>
        <div class="coupon-item-content">
          <h6>折扣券、所有商品{{ item.discount * 10 }}折</h6>
          <p>有效期{{item.expiry_time}}</p>
        </div>
        <div class="btn" :style="{ background:time >=item.expiry_time ?'linear-gradient(270deg, #E8E8E8 0%, #D2D2D2 100%)':'background: linear-gradient(270deg, #63A4FF 0%, #175BFF 100%);'  }">
          <span @click="$router.push('/shop')">立即使用</span>
          <img v-show="time >=item.expiry_time" src="@assets/img/profile/icon-expire.png"/>
        </div>
      </div>
    </div>
    <div v-else class="content">
      <div class="notBox">
        <img src="@assets/img/not_Coupon.png">
        <span>暂无优惠券</span>
      </div>
    </div>
  </div>
</template>

<script>
import {getCoupon, getCouponList, myCoupon} from "@assets/api/coupons";

export default {
  name:'',
  components: {},

  data () {
    return {
      coupons_bg:require('@assets/img/profile/coupons_bg.png'),
      coupons_bg_expire:require('@assets/img/profile/coupons_bg-expire.png'),
      couponList:[],
      time:'',//当前时间
    };
  },

  computed: {},

  created(){
    this.getMyCouponData()
    this.getNowDate()
    console.log('我哦的的的的的')
  },

  methods: {
    //获取优惠券列表
    getMyCouponData(){
      myCoupon().then(res=>{
        let str = ''
        if(res.code == 1){
          this.couponList = res.data
          this.couponList.forEach(item=>{
            str =  item.expiry_time.split(' ')
            this.$set(item,'expiry_time',str[0])
          })
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },

    //获取当前时间
    getNowDate () {
      var date = new Date();
      var sign2 = ":";
      var year = date.getFullYear() // 年
      var month = date.getMonth() + 1; // 月
      var day = date.getDate(); // 日
      var hour = date.getHours(); // 时
      var minutes = date.getMinutes(); // 分
      var seconds = date.getSeconds() //秒
      var weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
      var week = weekArr[date.getDay()];
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      this.time = year + "-" + month + "-" + day
      // return year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
    },
    //领取优惠券
    receiveCoupon(data){
      console.log(data)
      if(data.get_status != 1 && this.time<=data.expiry_time){
        let params = {
          coupon_id:data.id
        }
        console.log(data)
        getCoupon(params).then(res=>{
          console.log('res',res)
          if(res.code == 1){
            this.$Message.success(`${res.msg}`)
            this.getCouponListData()
          }else{
            this.$Message.error(`${res.msg}`)
          }
        })
      }else{
        this.$router.push('/Shop')
      }
    },
  },

}

</script>
<style lang='less' scoped>
@import '@assets/css/profile/account/coupon.less';
</style>